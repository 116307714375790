<template>
    <div class="edit-profile-detail">
        <div class="title">세부사항</div>
        <div class="section">
            <div class="section-title required">직장/직업</div>
            <div class="search-box-wrapper">
                <div class="search-box">{{ job_category.name }}</div>
            </div>
            <div class="section current" @click="setEmployStatus">
                <Toggler v-model="employmentStatus" class="m-r-10" />

                <div class="section-title">현재 재직중</div>
            </div>
        </div>
        <div class="section">
            <div class="section-title required">담당업무</div>
            <TextareaWithX placeholder="하시는 업무에 대해서 적어주세요." v-model="jobName" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'JobTypeB',
    props: ['type', 'edit', 'job_category'],
    data: () => ({
        employmentStatus: 1,
        job_name: null,
    }),
    computed: {
        profile() {
            return this.$store.getters.profile
        },
        jobName: {
            get() {
                return this.job_name
            },
            set(value) {
                if (value) {
                    this.$emit('disabled', false)
                } else {
                    this.$emit('disabled', true)
                }
                this.job_name = value
            },
        },
    },
    methods: {
        setEmployStatus() {
            this.employmentStatus = Number(!this.employmentStatus)
        },
        onSave(key) {
            let companies = this.profile.company
            const company = {
                job_category_name: this.job_category.name,
                job_category_id: this.job_category.id,
                company_task: this.jobName,
                employment_status: this.employmentStatus,
            }

            if (this.edit) {
                if (this.edit.id) {
                    company.id = this.edit.id
                    companies = companies.filter(item => item.id !== this.edit.id)
                } else {
                    companies = companies.filter((item, idx) => idx !== this.edit.idx)
                }
            }
            this.$set(this.profile, 'company', [...companies, company])
        },
        init() {
            if (this.edit) {
                this.employmentStatus = this.edit.employment_status
                this.jobName = this.edit.company_task
            }
        },
    },
    mounted() {
        this.init()
    },
}
</script>

<style scoped lang="scss">
.current {
    margin-top: 32px;
    padding: 0 !important;
}
</style>
